
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as index8_45YXU8kv2TXBFC5ALhjrDlmO3DYA1F8Es3FcDO0F6iMMeta } from "/app/pages/help/index.vue?macro=true";
import { default as indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta } from "/app/pages/login/index.vue?macro=true";
import { default as indexFEkHaFTMMS9vt4XrRHGS2dzzpi2EhbWW2i4oNtz6gbQMeta } from "/app/pages/logout/index.vue?macro=true";
import { default as indexKGFpJrfaPGuuhppiHh6jzgX1cc0MaKSlIxKJ_45q3hxrYMeta } from "/app/pages/account/index.vue?macro=true";
import { default as indexjDugcZSX_45NI_JSxZVbHEwoMamvqp3ncrQgMlhb8kY4kMeta } from "/app/pages/booking/index.vue?macro=true";
import { default as indexaGQBX41dZRwcZqeZq9bN4rEOhix7kYinxBstFsxgKSoMeta } from "/app/pages/maintenance/index.vue?macro=true";
import { default as index1kCB5Ba5IEXxgHeAhOkU1Bhyx2yBi2UqwPbzCcj5kTgMeta } from "/app/pages/account/cards/index.vue?macro=true";
import { default as indexujN3OBvzKuSmNRPACNwuRyK0J_rI8oIf_37duxa2w1YMeta } from "/app/pages/cookiespolicy/index.vue?macro=true";
import { default as indexkSmzMOCxOvC7MSAbTJr9NATbij9CqWlUsUvkzSGXI1sMeta } from "/app/pages/privacypolicy/index.vue?macro=true";
import { default as indexFTgIbYcxhaesYBFQ2Ua_454qVcuF_n_fR1Id4icjy4nfEMeta } from "/app/pages/account/orders/index.vue?macro=true";
import { default as indexB5XxJFUr0WRt6Nzeld8aSOWSmmci8A__hbQCZcFIU5UMeta } from "/app/pages/login/register/index.vue?macro=true";
import { default as indexyX39TphhU0tvQbyZY4HvHhWSf2ywM4Xlccc5w_45rfprIMeta } from "/app/pages/account/profile/index.vue?macro=true";
import { default as indexTZBZN1T1l8NYshninPgB2mTZf82ofGhZndA6wE_LEVoMeta } from "/app/pages/checkout/payment/index.vue?macro=true";
import { default as indexNmHpSVxKSf9q8hBh6PCJmXovKW55LfKdN0jc9KvzuuoMeta } from "/app/pages/account/addresses/index.vue?macro=true";
import { default as _91uuid_93GwfGWfLbrEJn5wvSJDDXUgYg5QQbZaYhCKXL6CpylVQMeta } from "/app/pages/checkout/payment/[uuid].vue?macro=true";
import { default as indexNRKtsBinUlgZMeDRBtvVpMXaEydqBrjS8ayplVEwrcEMeta } from "/app/pages/checkout/takeaway/index.vue?macro=true";
import { default as indexQX9jZmfrih5UmmFr46QhWf5GPKCRBVOAmac3yG2qmskMeta } from "/app/pages/login/confirmemail/index.vue?macro=true";
import { default as indexkaoTcX2XOSd8qXPXyz8HJHaRugfjR8u1j3iqgJ2pmKYMeta } from "/app/pages/termsandconditions/index.vue?macro=true";
import { default as _91uuid_93i33zYsPAaYKxiyvP8c0TUZeQ9O3JyQzYSmw24lGc3GwMeta } from "/app/pages/checkout/trackorder/[uuid].vue?macro=true";
import { default as indexfsWmnkHmYuEs6yuk_L1DcRyGD5hYMy7p0RRjS8PunjAMeta } from "/app/pages/login/forgotpassword/index.vue?macro=true";
import { default as _91uuid_93P_KMrIM7yVLq7szx1TSZqIgODzH98890daGtjYmrglwMeta } from "/app/pages/login/resetpassword/[uuid].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/app/pages/help/index.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta || {},
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: indexFEkHaFTMMS9vt4XrRHGS2dzzpi2EhbWW2i4oNtz6gbQMeta || {},
    component: () => import("/app/pages/logout/index.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexKGFpJrfaPGuuhppiHh6jzgX1cc0MaKSlIxKJ_45q3hxrYMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/app/pages/booking/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexaGQBX41dZRwcZqeZq9bN4rEOhix7kYinxBstFsxgKSoMeta || {},
    component: () => import("/app/pages/maintenance/index.vue")
  },
  {
    name: "account-cards",
    path: "/account/cards",
    meta: index1kCB5Ba5IEXxgHeAhOkU1Bhyx2yBi2UqwPbzCcj5kTgMeta || {},
    component: () => import("/app/pages/account/cards/index.vue")
  },
  {
    name: "cookiespolicy",
    path: "/cookiespolicy",
    component: () => import("/app/pages/cookiespolicy/index.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("/app/pages/privacypolicy/index.vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexFTgIbYcxhaesYBFQ2Ua_454qVcuF_n_fR1Id4icjy4nfEMeta || {},
    component: () => import("/app/pages/account/orders/index.vue")
  },
  {
    name: "login-register",
    path: "/login/register",
    meta: indexB5XxJFUr0WRt6Nzeld8aSOWSmmci8A__hbQCZcFIU5UMeta || {},
    component: () => import("/app/pages/login/register/index.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: indexyX39TphhU0tvQbyZY4HvHhWSf2ywM4Xlccc5w_45rfprIMeta || {},
    component: () => import("/app/pages/account/profile/index.vue")
  },
  {
    name: "checkout-payment",
    path: "/checkout/payment",
    meta: indexTZBZN1T1l8NYshninPgB2mTZf82ofGhZndA6wE_LEVoMeta || {},
    component: () => import("/app/pages/checkout/payment/index.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    meta: indexNmHpSVxKSf9q8hBh6PCJmXovKW55LfKdN0jc9KvzuuoMeta || {},
    component: () => import("/app/pages/account/addresses/index.vue")
  },
  {
    name: "checkout-payment-uuid",
    path: "/checkout/payment/:uuid()",
    meta: _91uuid_93GwfGWfLbrEJn5wvSJDDXUgYg5QQbZaYhCKXL6CpylVQMeta || {},
    component: () => import("/app/pages/checkout/payment/[uuid].vue")
  },
  {
    name: "checkout-takeaway",
    path: "/checkout/takeaway",
    meta: indexNRKtsBinUlgZMeDRBtvVpMXaEydqBrjS8ayplVEwrcEMeta || {},
    component: () => import("/app/pages/checkout/takeaway/index.vue")
  },
  {
    name: "login-confirmemail",
    path: "/login/confirmemail",
    meta: indexQX9jZmfrih5UmmFr46QhWf5GPKCRBVOAmac3yG2qmskMeta || {},
    component: () => import("/app/pages/login/confirmemail/index.vue")
  },
  {
    name: "termsandconditions",
    path: "/termsandconditions",
    component: () => import("/app/pages/termsandconditions/index.vue")
  },
  {
    name: "checkout-trackorder-uuid",
    path: "/checkout/trackorder/:uuid()",
    meta: _91uuid_93i33zYsPAaYKxiyvP8c0TUZeQ9O3JyQzYSmw24lGc3GwMeta || {},
    component: () => import("/app/pages/checkout/trackorder/[uuid].vue")
  },
  {
    name: "login-forgotpassword",
    path: "/login/forgotpassword",
    meta: indexfsWmnkHmYuEs6yuk_L1DcRyGD5hYMy7p0RRjS8PunjAMeta || {},
    component: () => import("/app/pages/login/forgotpassword/index.vue")
  },
  {
    name: "login-resetpassword-uuid",
    path: "/login/resetpassword/:uuid()",
    component: () => import("/app/pages/login/resetpassword/[uuid].vue")
  }
]