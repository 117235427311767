<script lang="ts" setup>
const notify = useNotify();

const { $http } = useNuxtApp();
const { initialize } = analyticsUtils();

const authStore = useAuthStore();
const stripeStore = useStripeStore();
const preferenceStore = usePreferenceStore();
const cartStore = useCartStore();

const loadPreference = async (): Promise<void> => {
  const preference = await $http.preference.get();

  if (!preference) {
    return;
  }

  preferenceStore.setPreference(preference);
};

try {
  await Promise.all([
    loadPreference(),
    cartStore.load(),
    stripeStore.loadPublishableKey(),
    authStore.refreshProfile()
  ]);
} catch (error) {
  if (import.meta.client && error instanceof APIError) {
    notify.error(error.message);
  }
}

const gTag: string | null = preferenceStore.preference.general.gTag;

if (gTag) {
  initialize(gTag);
}

defineHead(preferenceStore.preference);

const description = preferenceStore.preference.general.seo?.description;
const openGraphImage = preferenceStore.preference.general.seo?.openGraphImage;

useSeoMeta({
  description,
  ogDescription: description,
  ogImage: openGraphImage || undefined,
});

onMounted(() => {
  eventBus.on(Events.SERVICE_ERROR, (event) => notify.error(String(event)));
});

onUnmounted(() => {
  eventBus.off(Events.SERVICE_ERROR);
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtPwaManifest />

    <NuxtLayout>
      <NuxtPage />

      <UNotifications />
      <UModals />
    </NuxtLayout>
  </div>
</template>
