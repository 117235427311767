import type { $Fetch } from 'nitropack';

import type { Address } from '~/models';

import type { DefaultFilter } from './filter';

export default class AddressService {
  constructor(private fetch: $Fetch) {}

  async delete(uuid: string): Promise<void> {
    return await this.fetch(`/addresses/${uuid}`, {
      method: 'DELETE'
    });
  }

  async list(): Promise<Address[] | null> {
    const store = useAuthStore();

    return await this.fetch<Address[] | null>('/addresses/', {
      query: {
        userId: store.profile?.userId ?? 0
      }
    });
  }

  async post(address: Partial<Address>): Promise<Address | null> {
    const store = useAuthStore();

    address.userId = store.isGuest ? 1 : store.profile?.userId;
    return await this.fetch<Address | null>('/addresses/', {
      method: 'POST',
      body: address
    });
  }

  async put(address: Partial<Address>): Promise<Address | null> {
    const { uuid, ...body } = address;
    return await this.fetch<Address | null>(`/addresses/${uuid}`, {
      method: 'PUT',
      body
    });
  }

  async confirmPhone(phone: string, code: string): Promise<void> {
    await this.fetch('/addresses/confirmphone', {
      method: 'POST',
      body: { phone, code }
    });
  }

  async getConfirmPhone(phone: string, resend?: boolean): Promise<boolean> {
    return await this.fetch<boolean>(`addresses/confirmphone/${phone}`, {
      method: 'GET',
      query: {
        resend: resend
      }
    });
  }
}

export type { DefaultFilter as Filter };
