const useNotify = () => {
  const toast = useToast();
  const reportStore = useReportStore();

  const error = (error: unknown): void => {
    let message: string = 'An error occurred. Please try again later.';

    if (error instanceof Error) {
      message = error.message;
    }

    if (typeof error === 'string') {
      message = error;
    }

    reportStore.saveLog({
      message: `[CUSTOMER]: ${message}`
    });

    toast.add({
      title: message,
      color: 'rose'
    });
  };

  const success = (message: string): void => {
    toast.add({
      title: message,
      color: 'green'
    });
  };

  return {
    error,
    success
  };
};

export { useNotify };
