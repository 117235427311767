import type { $Fetch } from 'nitropack';

import type { LogMessage } from '~/models';

export default class ReportService {
  constructor(private fetch: $Fetch) {}

  async saveLog(message: LogMessage): Promise<void> {
    return await this.fetch('/reports/logs', {
      method: 'POST',
      body: message
    });
  }
}
