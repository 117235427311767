import type { Cart, Discount, Item, Takeaway, Voucher } from '~/models';

import { useNuxtApp } from '#app';
import { useItem } from '~/composables/useItem';

const round = (value: number): number => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

const useCartStore = defineStore(
  'customer-cart',
  () => {
    const { $http } = useNuxtApp();

    const defaultCart = (): Cart => ({
      comment: '',
      items: [],
      orderType: 'Delivery'
    });

    const defaultTakeaway = (): Takeaway => ({
      type: cart.value.orderType,
      fullName: '',
      phone: '',
      addressLine1: '',
      area: '',
      postalCode: '',
      isPhoneValid: false
    });

    const cartUuid = ref<string>('');
    const serviceCharge = ref<number>(0);
    const orderDiscount = ref<number>(0);

    const cart = ref<Cart>(defaultCart());
    const takeaway = ref<Takeaway>(defaultTakeaway());
    const voucher = ref<Voucher | null>(null);
    const voucherStore = useVoucherStore();
    const discountStore = useDiscountStore();

    const updateItemDiscount = async (
      takeawayType: Takeaway['type'] = 'Delivery'
    ): Promise<void> => {
      const takeawayOption = takeawayType.toUpperCase();

      for (const item of cart.value.items) {
        item.discount = await discountStore.fixed({
          productUuid: item.productUuid,
          totalItem: item.price + item.extra,
          takeawayOption
        });
      }

      await save();
    };

    const loadOrderDiscount = async (
      takeawayType: Takeaway['type'] = 'Delivery'
    ): Promise<void> => {
      const takeawayOption = takeawayType.toUpperCase();

      orderDiscount.value = await discountStore.order({
        totalOrder: subtotal.value,
        takeawayOption
      });
    };

    const clearOrderDiscount = (): void => {
      orderDiscount.value = 0;
    };

    const load = async (): Promise<void> => {
      if (cartUuid.value) {
        try {
          const response = await $http.cart.get(cartUuid.value);
          if (response) {
            cart.value = response;
            setTakeawayType(cart.value.orderType);

            if (!orderDiscount.value) {
              orderDiscount.value = 0;
            }
          }
        } catch {
          cartUuid.value = '';
        }
      }
    };

    const save = async (): Promise<void> => {
      if (cart.value.uuid) {
        cart.value = await $http.cart.put(cart.value);
      } else {
        cart.value = await $http.cart.post(cart.value);
        cartUuid.value = cart.value.uuid!;
      }
    };

    const clear = async (): Promise<void> => {
      if (cartUuid.value) {
        await $http.cart.delete(cartUuid.value);
      }
      cartUuid.value = '';
      cart.value = defaultCart();
      takeaway.value = defaultTakeaway();
      voucher.value = null;
      serviceCharge.value = 0;
    };

    const addItem = (item: Item): void => {
      const itemInCart = cart.value.items.find((inCart) =>
        useItem(inCart).toEqual(item)
      );

      if (itemInCart) {
        itemInCart.quantity += item.quantity;
      } else {
        cart.value.items.push(item);
      }

      if (voucher.value) {
        voucher.value.valid = voucherStore.isValid(voucher.value.discount);
      }
      save();
    };

    const removeItem = async (item: Item): Promise<void> => {
      if (item.quantity > 1) {
        item.quantity--;
      } else {
        const index = cart.value.items.indexOf(item);
        cart.value.items.splice(index, 1);
      }

      if (voucher.value) {
        voucher.value.valid = voucherStore.isValid(voucher.value.discount);
      }

      await save();
    };

    const setVoucher = (payload: Voucher | null): void => {
      voucher.value = payload;
      cart.value.voucherUuid = payload?.discount.uuid;
      save();
    };

    const setComment = (payload: string): void => {
      cart.value.comment = payload;
      save();
    };

    const setTakeaway = async (payload: Takeaway | null): Promise<void> => {
      if (payload?.type !== takeaway.value.type) {
        await updateItemDiscount(payload?.type ?? 'Delivery');
      }

      takeaway.value = payload ?? defaultTakeaway();
      cart.value.orderType = takeaway.value.type;
    };

    const setTakeawayType = async (
      takeawayType: Takeaway['type']
    ): Promise<void> => {
      if (takeawayType !== takeaway.value.type) {
        await updateItemDiscount(takeawayType);
      }

      takeaway.value.type = takeawayType;
      cart.value.orderType = takeaway.value.type;
    };

    const setIsPhoneValid = async (isPhoneValid: boolean): Promise<void> => {
      takeaway.value.isPhoneValid = isPhoneValid;
    };

    const setServiceCharge = (fee: number): void => {
      serviceCharge.value = fee;
    };

    const deliveryFee = computed<number>(() => {
      return takeaway.value.type === 'Delivery' ? takeaway.value.fee ?? 0 : 0;
    });

    const voucherDiscount = computed<number>(() => {
      if (
        !voucher.value ||
        voucher.value.valid !== true ||
        !voucher.value.discount.value
      ) {
        return 0;
      }

      const total = round(
        subtotal.value + deliveryFee.value + serviceCharge.value
      );

      const getValue = (discount: Discount): number => {
        return discount.valueType === 'Percentage'
          ? round(total * (discount.value / 100))
          : discount.value;
      };

      return Math.min(getValue(voucher.value.discount), total);
    });

    const itemsDiscount = computed<number>(() => {
      return round(
        cart.value.items.reduce((acc, curr) => {
          return acc + curr.quantity * curr.discount;
        }, 0)
      );
    });

    const discountSubTotal = computed<number>(() => {
      return itemsDiscount.value + (orderDiscount.value || 0);
    });

    const discountSubTotalPercentage = computed<number>(() => {
      return round((discountSubTotal.value / subtotal.value) * 100);
    });

    const discount = computed<number>(() => {
      return discountSubTotal.value + voucherDiscount.value;
    });

    const subtotal = computed<number>(() => {
      const subtotal = cart.value.items.reduce((acc, curr) => {
        return acc + curr.quantity * (curr.price + curr.extra + curr.drs);
      }, 0);

      return round(subtotal);
    });

    const total = computed<number>(() => {
      return round(
        subtotal.value -
          discount.value +
          deliveryFee.value +
          serviceCharge.value
      );
    });

    const hasTakeaway = computed<boolean>(() => {
      return !!(takeaway.value.fullName && takeaway.value.phone);
    });

    return {
      cartUuid,
      items: computed<Item[]>(() => cart.value.items),
      takeaway,
      serviceCharge,
      comment: computed<string>(() => cart.value.comment),
      voucher: computed<Voucher | null>(() => voucher.value),
      load,
      clear,
      addItem,
      removeItem,
      setVoucher,
      setComment,
      setTakeaway,
      setTakeawayType,
      setIsPhoneValid,
      setServiceCharge,
      clearOrderDiscount,
      loadOrderDiscount,
      deliveryFee,
      voucherDiscount,
      itemsDiscount,
      discountSubTotal,
      discountSubTotalPercentage,
      subtotal,
      total,
      hasTakeaway
    };
  },
  {
    persist: [
      {
        pick: ['cartUuid'],
        storage: persistedState.localStorage
      },
      {
        pick: ['cartUuid'],
        storage: persistedState.cookiesWithOptions({
          path: '/',
          sameSite: 'strict',
          maxAge: 60 * 60 * 24 * 7,
          watch: true
        })
      }
    ]
  }
);

export { useCartStore };
