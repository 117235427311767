import type { LogMessage } from '~/models';

import { useNuxtApp } from '#app';

const useReportStore = defineStore('customer-report', () => {
  const { $http } = useNuxtApp();

  const saveLog = async (message: LogMessage): Promise<void> => {
    return $http.report.saveLog(message);
  };

  return {
    saveLog
  };
});

export { useReportStore };
